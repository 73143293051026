<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M12 22.0002C16.7883 22.0002 20.67 18.1185 20.67 13.3302C20.67 8.54185 16.7883 4.66016 12 4.66016C7.21165 4.66016 3.32996 8.54185 3.32996 13.3302C3.32996 18.1185 7.21165 22.0002 12 22.0002Z"
      fill="#9984D4"
    />
    <path
      d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
      fill="#9984D4"
    />
    <path
      d="M14.89 3.45H9.11001C8.71001 3.45 8.39001 3.13 8.39001 2.73C8.39001 2.33 8.71001 2 9.11001 2H14.89C15.29 2 15.61 2.32 15.61 2.72C15.61 3.12 15.29 3.45 14.89 3.45Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "TimerSvg",
};
</script>

<style scoped></style>
