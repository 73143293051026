<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#9984D4"
    />
    <path
      d="M10.6399 9.49945C10.4499 9.49945 10.2599 9.42945 10.1099 9.27945C9.39994 8.56945 8.23994 8.56945 7.52994 9.27945C7.23994 9.56945 6.75994 9.56945 6.46994 9.27945C6.17994 8.98945 6.17994 8.50945 6.46994 8.21945C7.75994 6.92945 9.86994 6.92945 11.1699 8.21945C11.4599 8.50945 11.4599 8.98945 11.1699 9.27945C11.0199 9.42945 10.8299 9.49945 10.6399 9.49945Z"
      fill="#9984D4"
    />
    <path
      d="M13.36 9.49945C13.17 9.49945 12.98 9.42945 12.83 9.27945C12.54 8.98945 12.54 8.50945 12.83 8.21945C14.12 6.92945 16.23 6.92945 17.53 8.21945C17.82 8.50945 17.82 8.98945 17.53 9.27945C17.24 9.56945 16.76 9.56945 16.47 9.27945C15.76 8.56945 14.6 8.56945 13.89 9.27945C13.75 9.42945 13.56 9.49945 13.36 9.49945Z"
      fill="#9984D4"
    />
    <path
      d="M12 11.9199C9.31 11.9199 7.12 14.1099 7.12 16.7999C7.12 17.4999 7.69 18.0799 8.39 18.0799H15.59C16.29 18.0799 16.86 17.5099 16.86 16.7999C16.88 14.1099 14.69 11.9199 12 11.9199Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "EmojiSadSvg",
};
</script>

<style scoped></style>
