<template>
  <vac :end-time="endTime" @finish="finish">
    <template v-slot:process="{ timeObj }">
      <span>{{ `${timeObj.h}:${timeObj.m}:${timeObj.s}` }}</span>
    </template>
    <template v-slot:finish>
      <span>Done!</span>
    </template>
  </vac>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    endTime: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    finish() {
      this.$emit("finish");
    },
  },
};
</script>
