var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clock-wrap"},[_c('div',{staticClass:"clock"},[_c('div',{staticClass:"clock-dial"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameType === 'long'),expression:"gameType === 'long'"}],attrs:{"src":require("@/assets/img/svg/clock-numbers.svg"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameType === 'demo'),expression:"gameType === 'demo'"}],attrs:{"src":require("@/assets/img/svg/clock-numbers-demo.svg"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameType === 'short'),expression:"gameType === 'short'"}],attrs:{"src":require("@/assets/img/svg/clock-numbers-short.svg"),"alt":""}})]),_c('div',{staticClass:"clock-fill"},[_c('div',{staticClass:"fill-area left"},[_c('div',{staticClass:"fill",style:({
            animationDuration: ((Math.floor(_vm.duration / 1000 / 2)) + "s"),
            transform: ("rotate(" + (_vm.getArrowMovedDeg < 180 ? _vm.getArrowMovedDeg : 180) + "deg) scaleX(-1)"),
          })})]),_c('div',{staticClass:"fill-area right"},[_c('div',{staticClass:"fill",style:({
            animationDuration: ((_vm.duration / 1000 / 2) + "s"),
            animationDelay: ((_vm.getArrowMovedDeg < 180 ? _vm.duration / 1000 / 2 : 0) + "s"),
            transform: ("rotate(" + (_vm.getArrowMovedDeg > 180 ? _vm.getArrowMovedDeg : 180) + "deg)"),
          })})])])]),_c('div',{staticClass:"clock-wrap__timer"},[_vm._v(" - "),(_vm.endDate)?_c('Countdown',{attrs:{"end-time":_vm.endDate},on:{"finish":_vm.finish}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }