<template>
  <div class="clock-wrap">
    <div class="clock">
      <div class="clock-dial">
        <img
          src="@/assets/img/svg/clock-numbers.svg"
          alt=""
          v-show="gameType === 'long'"
        />
        <img
          src="@/assets/img/svg/clock-numbers-demo.svg"
          alt=""
          v-show="gameType === 'demo'"
        />
        <img
          src="@/assets/img/svg/clock-numbers-short.svg"
          alt=""
          v-show="gameType === 'short'"
        />
      </div>
      <div class="clock-fill">
        <div class="fill-area left">
          <div
            class="fill"
            :style="{
              animationDuration: `${Math.floor(duration / 1000 / 2)}s`,
              transform: `rotate(${
                getArrowMovedDeg < 180 ? getArrowMovedDeg : 180
              }deg) scaleX(-1)`,
            }"
          ></div>
        </div>

        <div class="fill-area right">
          <div
            class="fill"
            :style="{
              animationDuration: `${duration / 1000 / 2}s`,
              animationDelay: `${
                getArrowMovedDeg < 180 ? duration / 1000 / 2 : 0
              }s`,
              transform: `rotate(${
                getArrowMovedDeg > 180 ? getArrowMovedDeg : 180
              }deg)`,
            }"
          ></div>
        </div>
      </div>
    </div>

    <div class="clock-wrap__timer">
      - <Countdown :end-time="endDate" v-if="endDate" @finish="finish" />
    </div>
  </div>
</template>

<script>
import Countdown from "./Countdown";

export default {
  name: "Clock",
  props: {
    endDate: {
      type: Number,
      default: () => 0,
    },
    startDate: {
      type: Number,
      default: () => 0,
    },
    duration: {
      type: Number,
      default: () => 0,
    },
    gameType: {
      default: () => "demo",
    },
  },
  computed: {
    getArrowMovedDeg() {
      const fullAnimationTime = this.endDate - this.startDate;
      const timeRunOut = Date.now() - this.startDate;
      const arrowMovedPercent = (timeRunOut * 100) / fullAnimationTime;
      return (360 * arrowMovedPercent) / 100;
    },
  },
  methods: {
    finish() {
      this.$emit("finish");
    },
  },
  components: {
    Countdown,
  },
};
</script>

<style scoped></style>
