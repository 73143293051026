<template>
  <div class="page-events game-page">
    <div class="game-page__logo">
      <img src="@/assets/img/game-title.png" alt="" />
    </div>

    <div class="page-events__chars">
      <div
        v-for="(item, index) in cryptoshoppiesAssigned"
        :key="`${index}-${item}`"
        class="mates-char"
        @click="openMyCollection(item, index)"
        :class="{ disabled: isDisabled, active: item }"
      >
        <div class="mates-char__wrap" v-if="!item">
          <RoundedButton>
            <template v-slot:icon>
              <PlusSvg />
            </template>
          </RoundedButton>

          <p class="mates-char__text">{{ $t(`${index + 1}-char`) }}</p>
        </div>

        <div class="mates-char__wrap" v-else>
          <button
            v-if="!gameContinues"
            class="mates-char__edit"
            @click="editSelectedCsh(index)"
          >
            Edit
          </button>
          <div class="mates-char__img">
            <character-model :image="item.image" />
          </div>

          <div class="char-stats">
            <div
              v-for="([key, value], index) in Object.entries(item.skills)"
              :key="index"
              class="char-stats__row"
            >
              <div class="char-stats__title">
                {{ $t(`stat-${key}`) }}
              </div>

              <div class="char-stats__amount">{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GameOptions
      v-if="!gameContinues && !isDisabled"
      :cryptoshoppies-assigned="cryptoshoppiesAssigned"
      @refreshGame="initSpaceInvaders"
    />
    <Clock
      v-if="gameContinues && !gameTimeIsOver && timeToLeft && !isDisabled"
      :duration="timeToLeft"
      :end-date="endDate"
      :start-date="startDate"
      @finish="finish"
    />
    <GameToppedUp
      v-if="gameContinues && gameTimeIsOver && !isDisabled"
      :total-reward-earned="totalRewardEarned"
      @refreshGame="initSpaceInvaders"
    />
    <GameRecharge v-if="isDisabled" />
    <transition name="fade">
      <MyCollection
        v-if="isMyCollModal"
        @close="isMyCollModal = false"
        @selectMate="selectMate"
        :sort-type="'SKILLS'"
        :show-filter="false"
        :show-character-commissions="false"
        :show-character-stats="true"
      />
    </transition>
  </div>
</template>

<script>
import RoundedButton from "../../components/common/buttons/RoundedButton";
import PlusSvg from "../../components/icons/PlusSvg";
import CharacterModel from "../../components/character/СharacterModel";
import GameOptions from "./GameOptions";
import Clock from "../../components/common/Clock";
import GameToppedUp from "./GameToppedUp";
import GameRecharge from "./GameRecharge";
import MyCollection from "../../components/modals/MyCollection";
import api from "../../api/api";
import LoadShoppieByIdMixin from "../../mixins/loadShoppieByIdMixin";
import SetLoading from "../../mixins/general/SetLoading";
import SetErrorMixin from "../../mixins/general/SetErrorMixin";
import errors from "../../utils/errors";
import GetSpaceInvidersInfoMixin from "../../mixins/game/GetSpaceInvidersInfoMixin";
import { mapActions } from "vuex";

export default {
  name: "MiniGame",
  mixins: [
    LoadShoppieByIdMixin,
    SetLoading,
    SetErrorMixin,
    GetSpaceInvidersInfoMixin,
  ],
  data() {
    return {
      isDisabled: false,
      isMyCollModal: false,

      gameContinues: false,
      gameTimeIsOver: false,
      timeToLeft: 0,
      cryptoshoppiesAssigned: [null, null, null],
      totalRewardEarned: 0,
      endDate: 0,
      startDate: 0,
      shoppieCellIndex: -1,
    };
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    editSelectedCsh(index) {
      this.cryptoshoppiesAssigned[index] = null;
      this.$forceUpdate();
    },
    finish() {
      this.initSpaceInvaders();
    },
    openMyCollection(item, index) {
      if (item) return;
      this.isMyCollModal = true;
      this.shoppieCellIndex = index;
    },
    selectMate(mate) {
      const shoppies = [...this.cryptoshoppiesAssigned];
      const isMateAlreadyAdded = !!shoppies.find((i) => i && i.id === mate.id);
      if (isMateAlreadyAdded) {
        return this.setError(errors.CHARACTER_ALREADY_ADDED);
      }
      shoppies[this.shoppieCellIndex] = mate;
      this.cryptoshoppiesAssigned = shoppies;
      this.isMyCollModal = false;
    },
    async isGameAllowed() {
      try {
        const response = await api.getUserCryptoshoppies({
          limit: 3,
          skip: 0,
        });
        return response.total >= 3;
      } catch {
        return false;
      }
    },
    async initSpaceInvaders() {
      this.setLoading(true);
      const character = this.$route.params.character;
      this.loadBalance();
      const response = await this.getSpaceInvadersInfo();
      if (
        !response.data.gameData ||
        (response.data.gameData && response.data.gameData.type !== "demo")
      ) {
        this.isDisabled = !(await this.isGameAllowed());
      }
      if (response.data.isDemoGamePassed) {
        if (response.data.isGameInProgress) {
          this.gameContinues = true;
          const shoppies = [];
          for (const item of response.data.gameData.cryptoshoppiesAssigned) {
            await this.getCryptoshoppiesById(item);
            shoppies.push(this.character);
            this.character = null;
          }
          this.cryptoshoppiesAssigned = shoppies;
          this.endDate = Date.parse(response.data.gameData.endDate);
          this.startDate = Date.parse(response.data.gameData.createdAt);
          const timeToLeft = this.endDate - Date.now();
          if (timeToLeft <= 0) {
            this.gameTimeIsOver = true;
            this.totalRewardEarned = response.data.gameData.totalRewardEarned;
          } else {
            this.timeToLeft = timeToLeft;
            this.gameTimeIsOver = false;
          }
        } else {
          this.cryptoshoppiesAssigned = [
            character && !this.isDisabled ? character : null,
            null,
            null,
          ];
          this.gameContinues = false;
          this.gameTimeIsOver = true;
          this.timeToLeft = 0;
          this.totalRewardEarned = 0;
          this.endDate = 0;
          this.startDate = 0;
          this.shoppieCellIndex = -1;
        }
        this.setLoading(false);
      } else {
        this.setLoading(false);
        await this.$router.push({
          name: "DemoGame",
          params: {
            character: !!character && character,
          },
        });
      }
    },
  },
  async mounted() {
    await this.initSpaceInvaders();
  },
  components: {
    MyCollection,
    GameRecharge,
    GameToppedUp,
    Clock,
    GameOptions,
    CharacterModel,
    PlusSvg,
    RoundedButton,
  },
};
</script>

<style scoped></style>
