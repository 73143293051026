<template>
  <div class="topped-up">
    <div class="event-price">
      <div class="event-price__img">
        <CoinSilverSvg />
      </div>

      <div class="event-price__title">
        {{ $t("acc-topped-title") }}
      </div>

      <div class="event-price__notice">
        {{ $t("acc-topped-notice") }}
        <span
          >IGM {{ balanceBeforeDot(totalRewardEarned) }}.<span
            class="amount-sm"
            >{{ balanceAfterDot(totalRewardEarned) }}</span
          ></span
        >
      </div>
    </div>

    <div class="default-text">
      {{ $t("acc-topped-text") }}
    </div>

    <div class="game-list">
      <ul>
        <li class="game-list__item" v-for="n in 6" :key="n">
          <div class="game-list__point"></div>

          <div class="game-list__wrap">
            <div class="game-list__title">Event title</div>

            <div class="game-list__text">
              Its obverse depicts the Charter Oak, where according to legend
              Connecticut's charter was hidden to save.
            </div>
          </div>
        </li>
      </ul>
    </div>

    <button class="game-btn" @click="finishCurrentGame">
      {{ $t("game-new-btn") }}
    </button>
  </div>
</template>

<script>
import CoinSilverSvg from "../../components/icons/CoinSilverSvg";
import FormatNumberMixin from "../../mixins/common/FormatNumberMixin";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import api from "../../api/api";
export default {
  name: "GameToppedUp",
  mixins: [FormatNumberMixin, SetProcessingMixin],
  props: {
    totalRewardEarned: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    balanceBeforeDot(val) {
      return this.formatNumber(+val)
        .toString()
        .split(".")[0];
    },
    balanceAfterDot(val) {
      return (
        this.formatNumber(+val)
          .toString()
          .split(".")[1] || "00"
      );
    },
    async finishCurrentGame() {
      try {
        this.setLoading(true);
        await api.finishCurrentGame();
        this.setLoading(false);
        this.$emit("refreshGame");
      } catch (e) {
        this.setError({
          message: e.response.data.error.message,
        });
        this.setLoading(false);
      }
    },
  },
  components: { CoinSilverSvg },
};
</script>

<style scoped></style>
