<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#9984D4"
    />
    <path
      d="M15.5 10.1291C16.5383 10.1291 17.38 9.28744 17.38 8.24914C17.38 7.21085 16.5383 6.36914 15.5 6.36914C14.4617 6.36914 13.62 7.21085 13.62 8.24914C13.62 9.28744 14.4617 10.1291 15.5 10.1291Z"
      fill="#9984D4"
    />
    <path
      d="M8.5 10.1291C9.53829 10.1291 10.38 9.28744 10.38 8.24914C10.38 7.21085 9.53829 6.36914 8.5 6.36914C7.4617 6.36914 6.62 7.21085 6.62 8.24914C6.62 9.28744 7.4617 10.1291 8.5 10.1291Z"
      fill="#9984D4"
    />
    <path
      d="M15.6 12.9199H8.4C7.7 12.9199 7.13 13.4899 7.13 14.1999C7.13 16.8899 9.32 19.0799 12.01 19.0799C14.7 19.0799 16.89 16.8899 16.89 14.1999C16.88 13.4999 16.3 12.9199 15.6 12.9199Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "EmojiHappySvg",
};
</script>

<style scoped></style>
