<template>
  <div class="w-100">
    <Rules
      :title="$t('rules-game-title')"
      :text="$t('rules-game-text')"
      @click="isRulesModal = true"
    />

    <div class="game-page__wrap">
      <div class="game-page__subtitle">
        {{ $t("game-difficulty") }}
      </div>

      <div class="game-page__options">
        <div
          class="select-option"
          @click="difficulty = 'low'"
          :class="{ active: difficulty === 'low' }"
        >
          <EmojiHappySvg />

          {{ $t("game-difficulty-low") }}
        </div>

        <div
          class="select-option"
          @click="difficulty = 'medium'"
          :class="{ active: difficulty === 'medium' }"
        >
          <EmojiNormalSvg />

          {{ $t("game-difficulty-mid") }}
        </div>

        <div
          class="select-option"
          @click="difficulty = 'high'"
          :class="{ active: difficulty === 'high' }"
        >
          <EmojiSadSvg />

          {{ $t("game-difficulty-high") }}
        </div>
      </div>
    </div>

    <div class="game-page__wrap">
      <div class="game-page__subtitle">
        {{ $t("game-timing") }}
      </div>

      <div class="game-page__options">
        <div
          class="select-option select-option--big"
          @click="timing = 'short'"
          :class="{ active: timing === 'short' }"
        >
          <TimerSvg />

          <span>{{ $t("game-short") }} </span>
        </div>

        <div
          class="select-option select-option--big"
          @click="timing = 'long'"
          :class="{ active: timing === 'long' }"
        >
          <TimerSvg />

          <span> {{ $t("game-long") }}</span>
        </div>
      </div>
    </div>

    <button
      class="game-btn"
      @click="startGame"
      :disabled="cryptoshoppiesAssigned.filter((i) => i).length < 3"
    >
      {{ $t("game-start") }}
    </button>

    <transition name="fade">
      <GameRulesModal
        :rules-title="$t('rules-game-title')"
        v-if="isRulesModal"
        @close="isRulesModal = false"
      />
    </transition>
  </div>
</template>

<script>
import Rules from "../../components/common/Rules";
import EmojiHappySvg from "../../components/icons/EmojiHappySvg";
import EmojiNormalSvg from "../../components/icons/EmojiNormalSvg";
import EmojiSadSvg from "../../components/icons/EmojiSadSvg";
import TimerSvg from "../../components/icons/TimerSvg";
import GameRulesModal from "../../components/modals/GameRulesModal";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import api from "../../api/api";
export default {
  name: "GameOptions",
  mixins: [SetProcessingMixin],
  props: {
    cryptoshoppiesAssigned: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    difficulty: "medium",
    timing: "short",
    isRulesModal: false,
  }),
  methods: {
    async startGame() {
      try {
        this.setLoading(true);
        await api.startGame({
          cryptoshoppies: this.cryptoshoppiesAssigned.map((i) => i.id),
          duration: this.timing,
          difficulty: this.difficulty,
        });
        this.setLoading(false);
        this.$emit("refreshGame");
      } catch (e) {
        this.setLoading(false);
        this.setError({
          message: e.response.data.error.message,
        });
      }
    },
  },
  components: {
    GameRulesModal,
    TimerSvg,
    EmojiSadSvg,
    EmojiNormalSvg,
    EmojiHappySvg,
    Rules,
  },
};
</script>

<style scoped></style>
