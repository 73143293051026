<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#9984D4"
    />
    <path
      d="M8.81994 9.74945C7.96994 9.74945 7.11994 9.42945 6.46994 8.77945C6.17994 8.48945 6.17994 8.00945 6.46994 7.71945C6.75994 7.42945 7.23994 7.42945 7.52994 7.71945C8.23994 8.42945 9.39994 8.42945 10.1099 7.71945C10.3999 7.42945 10.8799 7.42945 11.1699 7.71945C11.4599 8.00945 11.4599 8.48945 11.1699 8.77945C10.5199 9.41945 9.66994 9.74945 8.81994 9.74945Z"
      fill="#9984D4"
    />
    <path
      d="M15.18 9.74945C14.33 9.74945 13.48 9.42945 12.83 8.77945C12.54 8.48945 12.54 8.00945 12.83 7.71945C13.12 7.42945 13.6 7.42945 13.89 7.71945C14.6 8.42945 15.76 8.42945 16.47 7.71945C16.76 7.42945 17.24 7.42945 17.53 7.71945C17.82 8.00945 17.82 8.48945 17.53 8.77945C16.88 9.41945 16.03 9.74945 15.18 9.74945Z"
      fill="#9984D4"
    />
    <path
      d="M15.6 12.6191H8.4C7.7 12.6191 7.13 13.1891 7.13 13.8991C7.13 16.5891 9.32 18.7791 12.01 18.7791C14.7 18.7791 16.89 16.5891 16.89 13.8991C16.88 13.1991 16.3 12.6191 15.6 12.6191Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "EmojiNormalSvg",
};
</script>

<style scoped></style>
